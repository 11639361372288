import React, { useState } from 'react';
import { Interactive } from '@react-three/xr';
import { useMenuStore } from '../stores/use-menu-store';
import { Primitive } from './primitive.component';
import { Vector3 } from 'three';
import { PrimitiveTypes } from './primitive.component';

interface ShapeButtonProps {
  type: PrimitiveTypes;
  position: Vector3;
}

export const ShapeButton = ({ type, position }: ShapeButtonProps) => {
  const { selectedShape, setSelectedShape } = useMenuStore((state) => ({
    selectedShape: state.selectedShape,
    setSelectedShape: state.setSelectedShape
  }));
  const [hover, setHover] = useState(false);
  const scale = hover || selectedShape === type ? 1.2 : 1;
  return (
    <Interactive
      onSelect={() => setSelectedShape(type)}
      onHover={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <Primitive type={type} position={position} scaleMultiplier={scale} />
    </Interactive>
  );
};
