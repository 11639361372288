import React, { Ref } from 'react';
import { Box, RoundedBox, Sphere, Cylinder, Cone } from '@react-three/drei';
import { Mesh, Vector3, Euler } from 'three';

export enum PrimitiveTypes {
  Box,
  RoundedBox,
  Sphere,
  Cylinder,
  Cone
}

export const PRIMITIVE_TYPES = Object.freeze({
  BOX: 1,
  ROUNDED_BOX: 2,
  SPHERE: 3,
  CYLINDER: 4,
  CONE: 5
});

type Args =
  | [
      width?: number | undefined,
      height?: number | undefined,
      widthSegments?: number | undefined,
      heightSegments?: number | undefined
    ]
  | undefined;

type RoundedBoxArgs =
  | [
      width?: number | undefined,
      height?: number | undefined,
      depth?: number | undefined
    ]
  | undefined;

type PrimitiveProps = {
  type: PrimitiveTypes;
  position?: THREE.Vector3;
  rotation?: THREE.Euler;
  color?: string;
  scaleMultiplier?: number;
  args?: number[];
  objectRef?: Ref<Mesh> | undefined;
};

const arrayToArgs = (args: number[]): Args => {
  return [args[0], args[1], args[2], args[3]];
};

const arrayToRoundedBoxArgs = (args: number[]): RoundedBoxArgs => {
  // width, height, depth
  return [args[0], args[1], args[2]];
};

export const Primitive = ({
  type,
  args = [0.1, 0.1, 0.1],
  position = new Vector3(0, 0, 0),
  rotation = new Euler(0, 0, 0),
  scaleMultiplier = 1,
  color = 'grey',
  objectRef
}: PrimitiveProps) => {
  switch (type) {
    case PrimitiveTypes.Box: {
      const scaledBoxArgs = args.map((arg: number) => {
        return arg * scaleMultiplier;
      });
      return (
        <Box
          ref={objectRef}
          args={arrayToArgs(scaledBoxArgs)}
          position={position}
          rotation={rotation}
        >
          <meshStandardMaterial attach="material" color={color} />
        </Box>
      );
    }
    case PrimitiveTypes.RoundedBox: {
      const scaledRoundedBoxArgs = args.map((x) => x * scaleMultiplier);
      const roundedBoxArgs = arrayToRoundedBoxArgs(scaledRoundedBoxArgs);
      return (
        <RoundedBox
          ref={objectRef}
          radius={0.01 * scaleMultiplier}
          args={roundedBoxArgs}
          position={position}
          rotation={rotation}
        >
          <meshStandardMaterial attach="material" color={color} />
        </RoundedBox>
      );
    }
    case PrimitiveTypes.Sphere: {
      return (
        <Sphere
          ref={objectRef}
          args={[scaleMultiplier * 0.06, 20, 25]}
          position={position}
          rotation={rotation}
        >
          <meshStandardMaterial attach="material" color={color} />
        </Sphere>
      );
    }
    case PrimitiveTypes.Cylinder: {
      const scaledCylinderArgs = [0.05, 0.05, 0.1].map(
        (x) => x * scaleMultiplier
      );

      return (
        <Cylinder
          ref={objectRef}
          args={arrayToArgs([...scaledCylinderArgs, 25])}
          position={position}
          rotation={rotation}
        >
          <meshStandardMaterial attach="material" color={color} />
        </Cylinder>
      );
    }
    case PrimitiveTypes.Cone: {
      return (
        <Cone
          ref={objectRef}
          args={[0.05 * scaleMultiplier, 0.1 * scaleMultiplier, 25]}
          position={position}
          rotation={rotation}
        >
          <meshStandardMaterial attach="material" color={color} />
        </Cone>
      );
    }
    default: {
      const scaledDefaultArgs = args.map((arg: number) => {
        return arg * scaleMultiplier;
      });
      return (
        <Box
          ref={objectRef}
          args={arrayToArgs(scaledDefaultArgs)}
          position={position}
          rotation={rotation}
        >
          <meshStandardMaterial attach="material" color={color} />
        </Box>
      );
    }
  }
};
