import React, { Suspense, useEffect, useReducer, useRef } from 'react';
import { Sky } from '@react-three/drei';
import {
  DefaultXRControllers,
  useController,
  useXR,
  useXRFrame
} from '@react-three/xr';
import Ambulance from './truck.component';
import { Physics } from '@react-three/cannon';
import { useSceneStore } from '../stores/use-scene-store';
import { Plane } from './plane.component';
import { CurrentItem } from './current-item.component';
import { Menu } from './menu.component';
import THREE from 'three';
import { Primitive } from './primitive.component';

const VRScene = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const playerRef = useRef<THREE.Group>(null!);
  const { player, isPresenting } = useXR();
  const rightController = useController('right');
  const leftController = useController('left');

  const { sceneItems } = useSceneStore((state) => ({
    sceneItems: state.sceneItems
  }));

  //I hate this but for some reason on the first state update the player- or menu moves
  //This instantly kicks off an update
  //Big TODO for sure
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    if (isPresenting)
      setTimeout(function () {
        forceUpdate();
      }, 1500);
  }, [isPresenting]);

  useXRFrame(() => {
    // do something on each frame of an active XR session
    if (rightController) {
      const lookLeftRight = rightController.inputSource.gamepad.axes[2];

      if (lookLeftRight !== 0) {
        player.rotation.y -= lookLeftRight * 0.05;
        if (playerRef?.current?.rotation) {
          playerRef.current.rotation.y -= lookLeftRight * 0.05;
        }
      }
    }
    // If the right B button is held down, then we use these controls in the control-item.component
    if (rightController?.inputSource.gamepad.buttons[4].pressed) {
      return;
    }
    if (leftController) {
      const strafe = leftController.inputSource.gamepad.axes[2];
      const moveForwardBack = leftController.inputSource.gamepad.axes[3];
      if (moveForwardBack !== 0) {
        player.translateZ(moveForwardBack * 0.05);
        if (playerRef.current) {
          playerRef.current.translateZ(moveForwardBack * 0.05);
        }
      }
      if (strafe !== 0) {
        player.translateX(strafe * 0.05);
        if (playerRef.current) {
          playerRef.current.translateX(strafe * 0.05);
        }
      }
    }
  });

  return (
    <>
      <Physics iterations={20}>
        <Suspense fallback={null}>
          <Sky sunPosition={[0, 1, 0]} />
          <ambientLight intensity={0.5} />
          <group ref={playerRef}>
            <CurrentItem />
            <Menu />
          </group>
          <Ambulance />
          {sceneItems.map((x) => {
            return (
              <Primitive
                key={x.id}
                type={x.type}
                position={x.position}
                rotation={x.rotation}
                color={x.color}
                scaleMultiplier={x.scaleMultiplier}
              />
            );
          })}
          <Plane color="green" />
          {/* <OrbitControls/> */}
        </Suspense>
        <DefaultXRControllers />
      </Physics>
    </>
  );
};

export default VRScene;
