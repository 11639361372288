import { PrimitiveTypes } from 'components/primitive.component';
import create from 'zustand';

interface MenuStoreState {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  selectedShape?: PrimitiveTypes;
  setSelectedShape: (shape: PrimitiveTypes) => void;
}

export const useMenuStore = create<MenuStoreState>((set) => ({
  selectedColor: 'green',
  setSelectedColor: (color: string) => set(() => ({ selectedColor: color })),
  selectedShape: undefined,
  setSelectedShape: (shape: PrimitiveTypes) =>
    set(() => ({ selectedShape: shape }))
}));
