/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Ambulance (props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/ambulance.gltf')
  return (
    <group ref={group} {...props} dispose={null} position={[-10, 0, 0]}>
      <mesh
        geometry={nodes.Mesh_doorLeft.geometry}
        material={nodes.Mesh_doorLeft.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorLeft_1.geometry}
        material={nodes.Mesh_doorLeft_1.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorLeft_2.geometry}
        material={nodes.Mesh_doorLeft_2.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorLeft_3.geometry}
        material={nodes.Mesh_doorLeft_3.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body.geometry}
        material={nodes.Mesh_body.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body_1.geometry}
        material={nodes.Mesh_body_1.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body_2.geometry}
        material={nodes.Mesh_body_2.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body_3.geometry}
        material={nodes.Mesh_body_3.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body_4.geometry}
        material={materials.lightFront}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body_5.geometry}
        material={nodes.Mesh_body_5.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_body_6.geometry}
        material={materials.lightBlue}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorRight.geometry}
        material={nodes.Mesh_doorRight.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorRight_1.geometry}
        material={nodes.Mesh_doorRight_1.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorRight_2.geometry}
        material={nodes.Mesh_doorRight_2.material}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.Mesh_doorRight_3.geometry}
        material={nodes.Mesh_doorRight_3.material}
        castShadow
        receiveShadow
      />
      <group scale={[-1, -1, -1]}>
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft001.geometry}
          material={nodes.Mesh_wheel_frontLeft001.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft001_1.geometry}
          material={nodes.Mesh_wheel_frontLeft001_1.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft001_2.geometry}
          material={nodes.Mesh_wheel_frontLeft001_2.material}
          castShadow
          receiveShadow
        />
      </group>
      <group scale={[-1, -1, -1]}>
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft002.geometry}
          material={nodes.Mesh_wheel_frontLeft002.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft002_1.geometry}
          material={nodes.Mesh_wheel_frontLeft002_1.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft002_2.geometry}
          material={nodes.Mesh_wheel_frontLeft002_2.material}
          castShadow
          receiveShadow
        />
      </group>
      <group scale={[-1, -1, -1]}>
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft.geometry}
          material={nodes.Mesh_wheel_frontLeft.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft_1.geometry}
          material={nodes.Mesh_wheel_frontLeft_1.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft_2.geometry}
          material={nodes.Mesh_wheel_frontLeft_2.material}
          castShadow
          receiveShadow
        />
      </group>
      <group scale={[-1, -1, -1]}>
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft003.geometry}
          material={nodes.Mesh_wheel_frontLeft003.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft003_1.geometry}
          material={nodes.Mesh_wheel_frontLeft003_1.material}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.Mesh_wheel_frontLeft003_2.geometry}
          material={nodes.Mesh_wheel_frontLeft003_2.material}
          castShadow
          receiveShadow
        />
      </group>
    </group>
  )
}

useGLTF.preload('/models/ambulance.gltf')
