import React from 'react';
import { VRCanvas } from '@react-three/xr';
import VRScene from './components/vr-scene.component';

export default function Viewer() {
  return (
    <VRCanvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
      <VRScene />
    </VRCanvas>
  );
}
