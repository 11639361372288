import React from 'react';
import { usePlane } from '@react-three/cannon';
import { Mesh } from 'three';

export const Plane = ({ color }: { color: string }) => {
  const [ref] = usePlane<Mesh>(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    color
  }));
  return (
    <mesh ref={ref}>
      <planeBufferGeometry args={[100, 100]} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
};
