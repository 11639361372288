import React, { useRef } from 'react';
import { RoundedBox } from '@react-three/drei';
import { useController } from '@react-three/xr';
import { useFrame } from '@react-three/fiber';
import { useMenuStore } from '../stores/use-menu-store';
import { ShapeButton } from './shape-button.component';
import { ColorButton } from './color-button.component';
import { PRIMITIVE_TYPES } from './primitive.component';
import { Vector3, Group } from 'three';

export const Menu = () => {
  const controller = useController('left');
  const mainRef = useRef<Group>(null);
  const { selectedColor, setSelectedColor } = useMenuStore((state) => ({
    selectedColor: state.selectedColor,
    setSelectedColor: state.setSelectedColor
  }));

  useFrame(() => {
    if (!controller || !mainRef.current) {
      return;
    }
    const { grip: control } = controller;

    mainRef.current.position.set(
      control.position.x,
      control.position.y,
      control.position.z
    );
    mainRef.current.rotation.set(
      control.rotation.x,
      control.rotation.y,
      control.rotation.z
    );
  });

  if (!controller) return null;

  return (
    <group ref={mainRef}>
      <mesh position={[0.02, 0.0, -0.18]} rotation={[-1.5, 0, -0.15]}>
        <MenuBox
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
        />
      </mesh>
    </group>
  );
};

interface MenuBoxProps {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
}

const MenuBox = ({ selectedColor, setSelectedColor }: MenuBoxProps) => {
  const boxes = [
    'black',
    'white',
    'red',
    'green',
    'forestgreen',
    'yellow',
    'blue',
    'skyblue',
    'pink',
    'gray',
    'darkgrey',
    'darkbrown',
    'brown',
    'orange',
    'purple'
  ];

  const offset = -((5 / 2) * 0.15) + 0.075;
  let colorRow = 1;
  let primRow = 1;
  const mainMenu = (
    <group scale={[0.25, 0.25, 0.25]} rotation={[0, 0, 0]}>
      <RoundedBox args={[0.75, 0.75, 0.75]}>
        <meshStandardMaterial attach="material" color="lightgrey" />
      </RoundedBox>
      <group>
        <mesh position={[offset, -0.15, 0.4]}>
          {Object.values(PRIMITIVE_TYPES).map((prim, i) => {
            console.log(prim);
            if (i % 5 === 0) primRow++;
            return (
              <ShapeButton
                key={prim}
                type={prim}
                position={new Vector3((i % 5) * 0.15, -primRow / 7 + 0.7, 0)}
              />
            );
          })}
        </mesh>
        <mesh position={[offset, -0.15, 0.4]}>
          {boxes.map((col, index) => {
            if (index % 5 === 0) colorRow++;
            const isSelected = col === selectedColor;
            return (
              <ColorButton
                key={index}
                onSelect={() => setSelectedColor(col)}
                color={col}
                index={index}
                colorRow={colorRow}
                isSelected={isSelected}
              />
            );
          })}
        </mesh>
      </group>
    </group>
  );

  return <mesh>{mainMenu}</mesh>;
};
