import React, { useState } from 'react'
import { Interactive, XRInteractionHandler } from '@react-three/xr'
import { Vector3 } from 'three'

type ColorButtonProps = {
  color: string
  onSelect: XRInteractionHandler | undefined
  colorRow: number
  index: number
  isSelected: boolean
}

export const ColorButton = ({
  onSelect,
  isSelected,
  color,
  colorRow,
  index
}: ColorButtonProps) => {
  const [hover, setHover] = useState(false)
  const scale =
    hover || isSelected ? new Vector3(1.2, 1.2, 1.2) : new Vector3(1, 1, 1)
  return (
    <Interactive
      onSelect={onSelect}
      onHover={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <mesh
        scale={scale}
        position={[(index % 5) * 0.15, -colorRow / 7 + 0.4, 0]}
      >
        <boxBufferGeometry attach="geometry" args={[0.1, 0.1, 0.1]} />
        <meshStandardMaterial attach="material" color={color} />
      </mesh>
    </Interactive>
  )
}
