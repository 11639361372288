import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useController, useXRFrame, useXREvent } from '@react-three/xr';
import { Mesh, Group, Vector3, Quaternion, Euler } from 'three';
import { useSceneStore } from '../stores/use-scene-store';
import { useMenuStore } from '../stores/use-menu-store';
import { Primitive } from './primitive.component';

export const CurrentItem = () => {
  const rightController = useController('right');
  const leftController = useController('left');
  const mainRef = useRef<Group>(null);
  const innerRef = useRef<Mesh>(null);

  const { selectedColor, selectedShape } = useMenuStore((state) => ({
    selectedColor: state.selectedColor,
    selectedShape: state.selectedShape
  }));

  const { sceneItems, setSceneItems } = useSceneStore((state) => ({
    sceneItems: state.sceneItems,
    setSceneItems: state.setSceneItems
  }));

  const getScale = (current: number, adjustment: number) => {
    if (adjustment === 0) {
      return current;
    }
    if ((adjustment < 0 && current > 0.1) || (adjustment > 0 && current < 10)) {
      const newScale = current + adjustment * 0.1;
      return adjustment < 0 ? Math.max(newScale, 0.1) : Math.min(newScale, 10);
    }
    return current;
  };

  const currentItem = selectedShape ? (
    <Primitive
      type={selectedShape}
      color={selectedColor}
      objectRef={innerRef}
    />
  ) : null;

  useXREvent(
    'select',
    () => {
      if (selectedShape && innerRef.current?.parent) {
        const position = new Vector3();
        position.setFromMatrixPosition(innerRef.current.parent.matrixWorld);
        const quaternion = new Quaternion();
        innerRef.current.getWorldQuaternion(quaternion);
        const rotation = new Euler();
        rotation.setFromQuaternion(quaternion);
        const scale = innerRef.current.scale;
        setSceneItems([
          ...sceneItems,
          {
            id: uuidv4(),
            type: selectedShape,
            position: new Vector3(position.x, position.y, position.z),
            rotation: rotation,
            color: selectedColor,
            scaleMultiplier: scale.x ? scale.x : 1
          }
        ]);
      }
    },
    { handedness: 'right' }
  );

  useXRFrame(() => {
    if (!rightController || !mainRef.current) {
      return;
    }
    const { grip: control } = rightController;
    if (rightController.inputSource.gamepad.buttons[4].pressed) {
      // If the right B button is held down, then we use these controls to scale the current item
      const moveForwardBack = leftController?.inputSource.gamepad.axes[3];
      if (moveForwardBack !== 0) {
        innerRef.current?.scale.set(
          getScale(innerRef.current.scale.x, moveForwardBack),
          getScale(innerRef.current.scale.y, moveForwardBack),
          getScale(innerRef.current.scale.z, moveForwardBack)
        );
      }
    }
    mainRef.current.position.set(
      control.position.x,
      control.position.y,
      control.position.z
    );
    mainRef.current.rotation.set(
      control.rotation.x,
      control.rotation.y,
      control.rotation.z
    );
  });

  if (!rightController) return null;
  return (
    <group ref={mainRef}>
      <mesh position={[0.02, 0.0, -0.18]} rotation={[0, 0, 0]}>
        {currentItem}
      </mesh>
    </group>
  );
};
