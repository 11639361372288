import { PrimitiveTypes } from 'components/primitive.component';
import create from 'zustand';

interface SceneItemState {
  sceneItems: SceneItem[];
  setSceneItems: (sceneItems: SceneItem[]) => void;
}

export const useSceneStore = create<SceneItemState>((set) => ({
  sceneItems: [],
  setSceneItems: (sceneItems: SceneItem[]) =>
    set(() => ({ sceneItems: sceneItems }))
}));

type SceneItem = {
  id: string;
  type: PrimitiveTypes;
  position: THREE.Vector3;
  rotation: THREE.Euler;
  color: string;
  scaleMultiplier: number;
};
